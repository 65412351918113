





















































import { Component, Vue } from "vue-property-decorator";
import api from "@/services/api";
import { mapState } from "vuex";
import handleValidationErrors from "@/store/handle-validation-errors";
import { GlobalMutationTypes } from "@/store/mutation-types";

@Component({
  computed: mapState(["isLoading"]),
})
export default class CompanyEditProfile extends Vue {
  companyData = {};
  created() {
    api.companies.getProfile().then((r) => {
      this.companyData = JSON.parse(JSON.stringify(r.data));
      this.$store.commit(GlobalMutationTypes.SET_LOGIN_DATA, r.data);
    });
  }
  onEditCompanyProfile() {
    api.companies
      .editProfile(this.companyData)
      .then((r) => {
        console.log(r.data);
        this.$store.commit(GlobalMutationTypes.SET_LOGIN_DATA, r.data);
      })
      .catch((e) => {
        const errors = handleValidationErrors(e.response.data).join(" ");
        console.log(errors);
        (this as any).$swal("Error!", errors, "error");
      });
  }
}
