











































































































import ValidationError from "@/components/ValidationError.vue";
import GradientButton from "@/components/GradientButton.vue";
import REGEX from "@/policies/regex";
import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import {
  email,
  regex,
  length,
  digits,
  integer,
  required,
  min_value,
  max_value,
} from "vee-validate/dist/rules";
import { AccountActionTypes } from "@/store/account-action-types";
import { mapState } from "vuex";

extend("email", email);
extend("regex", regex);
extend("length", length);
extend("digits", digits);
extend("integer", integer);
extend("required", required);
extend("min_value", min_value);
extend("max_value", max_value);

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    ValidationError,
    GradientButton,
  },
  computed: mapState(["isLoading"]),
})
export default class SignUp extends Vue {
  REGEX = REGEX;
  name = "";
  email = "";
  phone_number = "";
  password = "";
  responseErrors = [];
  locationError = false;
  location: { longitude: number; latitude: number } | null = null;

  onAskForLocation(): void {
    const geoOptions = { enableHighAccuracy: true };
    const geoSuccess = (l: any) => {
      const { longitude, latitude } = l.coords;
      this.location = { longitude, latitude };
    };
    navigator.geolocation.getCurrentPosition(
      geoSuccess,
      () => (this.locationError = true),
      geoOptions
    );
  }

  onSignup(): void {
    const { name, email, phone_number, location, password } = this;
    const body = {
      name,
      email,
      phone_number,
      location,
      password,
    };
    this.responseErrors = [];
    this.$store
      .dispatch(`company/${AccountActionTypes.signup}`, body)
      .then((r) => {
        (this as any)
          .$swal("Thank you!", r.data.message, "success")
          .then(() => {
            // redirect to home after closing the modal message
            this.$router.push({ name: "home" });
          });
      })
      .catch((r) => (this.responseErrors = r));
  }
}
