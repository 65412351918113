var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gradient-container"},[_c('h1',{staticClass:"page-title"},[_vm._v("Sign up")]),_c('div',{staticClass:"form-card"},[(_vm.successfulSignup)?_c('form',{attrs:{"id":"verification-form"},on:{"submit":function($event){$event.preventDefault();return _vm.onVerify($event)}}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("The verification code you received on your phone")]),_c('ValidationProvider',{attrs:{"name":"Verification Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.verificationCode),expression:"verificationCode"}],attrs:{"name":"verification code","type":"text","required":"","placeholder":"Enter your verification code"},domProps:{"value":(_vm.verificationCode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.verificationCode=$event.target.value}}}),_c('ValidationError',[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('GradientButton',{staticClass:"submit-button",attrs:{"type":"submit","disabled":invalid,"isLoading":_vm.isLoading}},[_vm._v("Verify")])],1)]}}],null,false,4055272444)})],1):_c('form',{attrs:{"id":"sign-up-form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSignup($event)}}},[_c('div',{staticClass:"errors-array-response"},_vm._l((_vm.responseErrors),function(err,index){return _c('ValidationError',{key:index},[_vm._v(" * "+_vm._s(err)+" ")])}),1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"sign-up-fields-divisor"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Your name")]),_c('ValidationProvider',{attrs:{"rules":"alpha_spaces","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],attrs:{"name":"name","type":"text","required":"","minlength":"5","placeholder":"Enter your name"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_c('ValidationError',[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Your email")]),_c('ValidationProvider',{attrs:{"name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"name":"email","type":"email","required":"","placeholder":"Enter your email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('ValidationError',[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Your phone")]),_c('ValidationProvider',{attrs:{"name":"Phone","rules":{ regex: _vm.REGEX.PHONE }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone_number),expression:"phone_number"}],attrs:{"name":"phone_number","type":"tel","required":"","placeholder":"Enter your phone number"},domProps:{"value":(_vm.phone_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone_number=$event.target.value}}}),_c('ValidationError',[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Your "),_c('abbr',{attrs:{"title":"Social Security Number / National ID Number"}},[_vm._v("SSN")])]),_c('ValidationProvider',{attrs:{"name":"SSN","rules":"digits:14"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ssn),expression:"ssn"}],attrs:{"name":"ssn","type":"text","placeholder":"Enter your SSN ID","required":""},domProps:{"value":(_vm.ssn)},on:{"input":function($event){if($event.target.composing){ return; }_vm.ssn=$event.target.value}}}),_c('ValidationError',[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Your salary")]),_c('ValidationProvider',{attrs:{"name":"Salary","rules":"required|integer|min_value:100|max_value:10000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.salary),expression:"salary"}],attrs:{"name":"salary","type":"text","placeholder":"Enter your salary"},domProps:{"value":(_vm.salary)},on:{"input":function($event){if($event.target.composing){ return; }_vm.salary=$event.target.value}}}),_c('ValidationError',[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Your password")]),_c('ValidationProvider',{attrs:{"name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"name":"password","type":"password","placeholder":"Enter your password","minlength":"8","required":""},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('ValidationError',[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)]),_c('GradientButton',{staticClass:"submit-button",attrs:{"type":"submit","disabled":invalid,"isLoading":_vm.isLoading}},[_vm._v("Sign up")])]}}])})],1)]),_c('div',{staticClass:"have-account-text"},[_vm._v(" Already have account? "),_c('router-link',{staticClass:"sign-in-link",attrs:{"to":{ name: 'user-signin' }}},[_vm._v("Sign in now")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }