import { render, staticRenderFns } from "./WaterOrders.vue?vue&type=template&id=bb99e962&"
import script from "./WaterOrders.vue?vue&type=script&lang=ts&"
export * from "./WaterOrders.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports