<template>
  <div class="gradient-container">
    <img
      src="@/assets/images/404.svg"
      alt="404 page not found"
      class="not-found-img"
    />
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
@import "@/assets/variables.less";

.not-found-img {
  min-width: 40%;
  max-width: 100%;
}
</style>
