




















































import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import api from "@/services/api";
import moment from "moment";

@Component({
  computed: mapState(["isLoading"]),
})
export default class AdminCompanies extends Vue {
  contactUsMessages = [] as any;

  created() {
    api.admins
      .getContactUsMessages()
      .then((r) => (this.contactUsMessages = r.data));
  }

  date(date: string) {
    return moment(date).format("Do MMMM, h:mm a");
  }
}
