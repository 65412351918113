

























































import ValidationError from "@/components/ValidationError.vue";
import GradientButton from "@/components/GradientButton.vue";
import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { AccountActionTypes } from "@/store/account-action-types";
import { mapState } from "vuex";

extend("required", required);

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    ValidationError,
    GradientButton,
  },
  computed: mapState(["isLoading"]),
})
export default class SignIn extends Vue {
  emailOrPhone = "";
  password = "";

  onLogin(): void {
    const { emailOrPhone, password } = this;
    const body = { email: emailOrPhone, phone_number: emailOrPhone, password };
    this.$store
      .dispatch(`company/${AccountActionTypes.login}`, body)
      .then(() => {
        this.$router.push({ name: "company-profile" });
      })
      .catch(({ response: r }) => {
        const responseError = r.status === 401 ? r.data.message : r.data;
        (this as any).$swal("We are sorry!", responseError, "error");
      });
  }
}
