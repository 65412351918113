
















































































































import ValidationError from "@/components/ValidationError.vue";
import GradientButton from "@/components/GradientButton.vue";
import api from "@/services/api";
import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapState } from "vuex";
import { AccountActionTypes } from "@/store/account-action-types";

extend("required", required);

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    ValidationError,
    GradientButton,
  },
  computed: mapState(["isLoading"]),
})
export default class SignIn extends Vue {
  emailOrPhone = "";
  password = "";
  isForgetPassword = false;
  forgetPasswordResponse = "";

  backToSignin(): void {
    this.isForgetPassword = false;
    this.forgetPasswordResponse = "";
  }

  onLogin(): void {
    const { emailOrPhone, password } = this;
    const body = { email: emailOrPhone, phone_number: emailOrPhone, password };

    this.$store
      .dispatch(`user/${AccountActionTypes.login}`, body)
      .then(() => {
        this.$router.push({ name: "user-profile" });
      })
      .catch(({ response: r }) => {
        const responseError = r.status === 401 ? r.data.message : r.data;
        (this as any).$swal("We are sorry!", responseError, "error");
      });
  }

  onResetPassword(): void {
    api.users
      .resetPassword({ email: this.emailOrPhone })
      .then((r) => {
        this.forgetPasswordResponse = r.data.message;
      })
      .catch(({ response: r }) => {
        const responseError = r.status === 401 ? r.data.message : r.data;
        (this as any).$swal("We are sorry!", responseError, "error");
      });
  }
}
