



















import { Component, Vue } from "vue-property-decorator";
import GradientButton from "@/components/GradientButton.vue";

@Component({
  components: {
    GradientButton,
  },
})
export default class GetStarted extends Vue {
  goToAdmin(): void {
    this.$router.push({ name: "admin-signin" });
  }
}
