






































































import { Component, Vue } from "vue-property-decorator";
import ProfileNavigationItem from "@/components/ProfileNavigationItem.vue";
import { mapState } from "vuex";
import api from "@/services/api";
import moment from "moment";

const stateColor = {
  ASSIGNED_TO_COMPANY: "yellow",
  READY_FOR_SHIPPING: "olive",
  ASSIGNED_TO_TRANSPORTER: "green",
  ON_ITS_WAY: "teal",
  DELIVERED: "blue",
};

@Component({
  components: {
    ProfileNavigationItem,
  },
  computed: mapState(["isLoading"]),
})
export default class CompanyWaterOrders extends Vue {
  waterOrders = [] as any;
  stateColor = stateColor;

  created() {
    api.companies.getAssignedOrders().then((r) => (this.waterOrders = r.data));
  }

  onReadyForShipping(order_id: string, index: number) {
    console.log("setting order", order_id, "as ready for shipping");
    api.companies
      .markOrderAsReadyForShipping(order_id)
      .then(() => {
        this.waterOrders[index].state = "READY_FOR_SHIPPING";
        (this as any).$swal(
          "Okay!",
          "order has been set as ready for shipping",
          "success"
        );
      })
      .catch((e) => {
        const error = e.response.data || e.response.statusText;
        (this as any).$swal("Error!", error, "error");
      });
  }

  date(date: string) {
    return moment(date).format("Do MMMM, h:mm a");
  }
}
