







import { Component, Vue } from "vue-property-decorator";
import NavigationBar from "@/components/NavigationBar.vue";

@Component({
  components: {
    NavigationBar,
  },
})
export default class App extends Vue {}
