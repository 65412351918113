









































































import { Component, Vue } from "vue-property-decorator";
import ProfileNavigationItem from "@/components/ProfileNavigationItem.vue";
import { mapState } from "vuex";
import api from "@/services/api";
import moment from "moment";

@Component({
  components: {
    ProfileNavigationItem,
  },
  computed: mapState(["isLoading"]),
})
export default class AdminTransporters extends Vue {
  transporters = [] as any[];

  created() {
    api.admins.getTransporters().then((r) => (this.transporters = r.data));
  }

  onApproveTransporter(transporter_id: string, index: number) {
    console.log("approving transporter", transporter_id);
    api.admins
      .approveTransporter(transporter_id)
      .then((r) => {
        console.log(r.data);

        this.transporters[index].is_approved = true;

        (this as any).$swal("Okay!", "Transporter approved", "success");
      })
      .catch((e) => {
        const error = e.response.data || e.response.statusText;
        (this as any).$swal("Error!", error, "error");
      });
  }

  onDeleteTransporter(transporter_id: string, index: number) {
    console.log("deleting transporter", transporter_id);
    api.admins
      .deleteTransporter(transporter_id)
      .then((r) => {
        console.log(r.data);
        this.transporters.splice(index, 1);
        (this as any).$swal("Okay!", "Transporter Deleted", "success");
      })
      .catch((e) => {
        const error = e.response.data || e.response.statusText;
        (this as any).$swal("Error!", error, "error");
      });
  }

  date(date: string) {
    return moment(date).format("Do MMMM, h:mm a");
  }
}
