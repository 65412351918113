








import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class GradientButton extends Vue {
  @Prop({ type: String }) path!: string;

  get isActive(): boolean {
    const r = this.$route.path.split("/").pop();
    return r === this.path;
  }
}
