












































import "@/components/Chart.js";
import api from "@/services/api";

import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";

type ConsumptionReport = {
  date: string;
  consumption: number;
};

@Component({
  computed: mapState(["isLoading"]),
})
export default class UserConsumptionReports extends Vue {
  showChart = false;
  charts = ["LineChart", "BarChart", "HorizontalBarChart"];

  chartType = "LineChart";
  myStyles = {
    maintainAspectRatio: true,
    marginTop: "2rem",
  };
  chartData = {
    labels: [] as any[],
    datasets: [
      {
        label: "Consumption",
        backgroundColor: "#00bdce50",
        borderColor: "#0071d2",
        borderWidth: 2,
        data: [] as any[],
      },
    ],
  };
  created() {
    api.users.getConsumptionReports().then((r) => {
      const consumption_reports: ConsumptionReport[] = r.data;
      const consumptions = consumption_reports.map((r) => r.consumption);
      const dates = consumption_reports.map((r) => r.date);
      this.chartData.labels = dates;
      this.chartData.datasets[0].data = consumptions;
      this.showChart = true;
    });
  }
}
