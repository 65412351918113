











































import { Component, Vue } from "vue-property-decorator";
import api from "@/services/api";
import { mapState } from "vuex";
import handleValidationErrors from "@/store/handle-validation-errors";

@Component({
  computed: mapState(["isLoading"]),
})
export default class UserNewWaterOrder extends Vue {
  amount = 1;
  locationError = false;
  location: { longitude: number; latitude: number } | null = null;

  onAskForLocation(): void {
    const geoOptions = { enableHighAccuracy: true };
    const geoSuccess = (l: any) => {
      const { longitude, latitude } = l.coords;
      this.location = { longitude, latitude };
    };
    navigator.geolocation.getCurrentPosition(
      geoSuccess,
      () => (this.locationError = true),
      geoOptions
    );
  }

  onNewWaterOrder() {
    const { amount, location } = this;
    const body = { amount, location };
    api.users
      .createNewWaterOrder(body)
      .then((r) => {
        (this as any).$swal(
          "Created",
          "Your water order has been created",
          "success"
        );
      })
      .catch((e) => {
        const errors = e.response.data;
        (this as any).$swal(
          "Error!",
          handleValidationErrors(errors).join(" "),
          "error"
        );
      });
  }
}
