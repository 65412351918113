













































































































































































import ValidationError from "@/components/ValidationError.vue";
import GradientButton from "@/components/GradientButton.vue";
import api from "@/services/api";
import REGEX from "@/policies/regex";
import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import {
  email,
  regex,
  length,
  digits,
  integer,
  required,
  min_value,
  max_value,
  min,
  alpha_spaces,
} from "vee-validate/dist/rules";
import { mapState } from "vuex";
import handleValidationErrors from "@/store/handle-validation-errors";
import { AccountActionTypes } from "@/store/account-action-types";
import { GlobalMutationTypes } from "@/store/mutation-types";
import { LoginTypes } from "@/policies/login-types";

extend("email", email);
extend("alpha_spaces", alpha_spaces);
extend("min", min);
extend("regex", regex);
extend("length", length);
extend("digits", digits);
extend("integer", integer);
extend("required", required);
extend("min_value", min_value);
extend("max_value", max_value);

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    ValidationError,
    GradientButton,
  },
  computed: mapState(["isLoading"]),
})
export default class SignUp extends Vue {
  REGEX = REGEX;
  name = "";
  email = "";
  phone_number = "";
  ssn = "";
  salary = "";
  password = "";
  verificationCode = "";
  responseErrors: string[] = [];
  successfulSignup = false;

  onSignup(): void {
    const { name, email, phone_number, ssn, salary, password } = this;
    const body = {
      name,
      email,
      phone_number,
      ssn,
      salary,
      password,
    };
    this.$store
      .dispatch(`user/${AccountActionTypes.signup}`, body)
      .then(() => (this.successfulSignup = true))
      .catch((errs) => (this.responseErrors = errs));
  }

  onVerify(): void {
    const { email, phone_number, verificationCode: code, password } = this;

    api.users
      .verify({ email, phone_number, code, password })
      .then(async (r) => {
        this.$store.commit(GlobalMutationTypes.SET_LOGIN_DATA, r.data.user);
        this.$store.commit(GlobalMutationTypes.SET_LOGIN_TOKEN, r.data.token);
        this.$store.commit(GlobalMutationTypes.SET_USER_TYPE, LoginTypes.USER);
        this.$router.push({ name: "user-profile" });
      })
      .catch(({ response: r }) => {
        const responseError = r.status === 401 ? r.data.message : r.data;
        (this as any).$swal("We are sorry!", responseError, "error");
      });
  }
}
