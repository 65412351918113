























































import api from "@/services/api";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required, min } from "vee-validate/dist/rules";
import { Component, Vue } from "vue-property-decorator";
import GradientButton from "@/components/GradientButton.vue";
import ValidationError from "@/components/ValidationError.vue";
import { mapState } from "vuex";

extend("min", min);

extend("required", required);

extend("password", {
  params: ["target"],
  validate(value, { target }: any) {
    return value === target;
  },
  message: "Password confirmation does not match",
});

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    GradientButton,
    ValidationError,
  },
  computed: mapState(["isLoading"]),
})
export default class ResetPassword extends Vue {
  password = "";
  passwordConfirmation = "";
  token = this.$route.query.token;

  onSubmit(): void {
    const { token, password } = this;
    api.users
      .changePassword({ token, password })
      .then((r) => {
        (this as any)
          .$swal("Password Changed!", r.data.message, "success")
          .finally(() => {
            this.$router.push({ name: "user-signin" });
          });
      })
      .catch(({ response: r }) => {
        const responseError = r.status === 401 ? r.data.message : r.data;
        (this as any).$swal("We are sorry!", responseError, "error");
      });
  }
}
