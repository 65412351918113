





























import { Component, Vue } from "vue-property-decorator";

import { latLng, Icon, LatLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

type D = Icon.Default & {
  _getIconUrl?: string;
};

delete (Icon.Default.prototype as D)._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

@Component({
  components: { LMap, LTileLayer, LMarker, LPopup, LTooltip },
})
export default class CompanyMap extends Vue {
  zoom = 15;
  url = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  attribution =
    '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
  cmpLocation = this.$store.state.loginData.location;
  currentCenter = latLng(this.cmpLocation[1], this.cmpLocation[0]);
  center = this.currentCenter;

  currentZoom = 11.5;
  mapOptions = {
    zoomSnap: 0.5,
  };

  zoomUpdate(zoom: number): void {
    this.currentZoom = zoom;
  }
  centerUpdate(center: LatLng): void {
    this.currentCenter = center;
  }
}
