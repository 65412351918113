

























































import { Component, Vue } from "vue-property-decorator";
import ProfileNavigationItem from "@/components/ProfileNavigationItem.vue";
import { mapState } from "vuex";
import api from "@/services/api";
import moment from "moment";

const stateColor = {
  PENDING: "orange",
  ASSIGNED_TO_COMPANY: "yellow",
  READY_FOR_SHIPPING: "olive",
  ASSIGNED_TO_TRANSPORTER: "green",
  ON_ITS_WAY: "teal",
  DELIVERED: "blue",
};

@Component({
  components: {
    ProfileNavigationItem,
  },
  computed: mapState(["isLoading"]),
})
export default class UserWaterOrders extends Vue {
  waterOrders = [] as any;
  stateColor = stateColor;

  created() {
    api.users.getMyWaterOrders().then((r) => (this.waterOrders = r.data));
  }

  date(date: string) {
    return moment(date).format("Do MMMM, h:mm a");
  }
}
