var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gradient-container"},[_c('h1',{staticClass:"page-title"},[_vm._v("Sign in")]),_c('div',{staticClass:"form-card"},[(_vm.isForgetPassword)?_c('form',{attrs:{"id":"forget-password-form"},on:{"submit":function($event){$event.preventDefault();return _vm.onResetPassword($event)}}},[(_vm.forgetPasswordResponse.length)?_c('div',{staticClass:"ui equal width centered grid"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('p',{staticClass:"ui large message"},[_vm._v(_vm._s(_vm.forgetPasswordResponse))])]),_c('span',{staticClass:"custom-link",on:{"click":_vm.backToSignin}},[_vm._v(" Back to Sign in")])]):_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Your email")]),_c('ValidationProvider',{attrs:{"name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.emailOrPhone),expression:"emailOrPhone"}],attrs:{"name":"email","type":"email","required":"","placeholder":"Enter your email"},domProps:{"value":(_vm.emailOrPhone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.emailOrPhone=$event.target.value}}}),_c('ValidationError',[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('span',{staticClass:"custom-link",on:{"click":function($event){_vm.isForgetPassword = false}}},[_vm._v(" Login with your password? ")])],1),_c('GradientButton',{attrs:{"type":"submit","disabled":invalid,"isLoading":_vm.isLoading}},[_vm._v("Reset password")])]}}],null,false,2335349504)})],1):_c('form',{attrs:{"id":"sign-in-form"},on:{"submit":function($event){$event.preventDefault();return _vm.onLogin($event)}}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Your email / phone number")]),_c('ValidationProvider',{attrs:{"name":"Email or Phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.emailOrPhone),expression:"emailOrPhone"}],attrs:{"name":"emailOrPhone","type":"text","required":"","placeholder":"Enter your email or phone"},domProps:{"value":(_vm.emailOrPhone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.emailOrPhone=$event.target.value}}}),_c('ValidationError',[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('span',{staticClass:"custom-link",on:{"click":function($event){_vm.isForgetPassword = true}}},[_vm._v(" Forget your password? ")])],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Your password")]),_c('ValidationProvider',{attrs:{"name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"name":"password","type":"password","placeholder":"Enter your password","minlength":"8","required":""},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('ValidationError',[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('GradientButton',{attrs:{"type":"submit","disabled":invalid,"isLoading":_vm.isLoading}},[_vm._v("Sign in")])]}}])})],1)]),_c('div',{staticClass:"no-account-text"},[_vm._v(" No account? "),_c('router-link',{staticClass:"sign-up-link",attrs:{"to":{ name: 'user-signup' }}},[_vm._v("Sign up now")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('img',{attrs:{"src":require("@/assets/images/email-sent.svg"),"alt":"email sent","width":"80%"}})])}]

export { render, staticRenderFns }