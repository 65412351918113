



































































import { Component, Vue } from "vue-property-decorator";
import ProfileNavigationItem from "@/components/ProfileNavigationItem.vue";
import { mapState } from "vuex";
import { AccountActionTypes } from "@/store/account-action-types";

@Component({
  components: {
    ProfileNavigationItem,
  },
  computed: mapState(["loginData"]),
})
export default class Profile extends Vue {
  onSignOut(): void {
    this.$store.dispatch(AccountActionTypes.logout);
  }

  get navigationNotSelected(): boolean {
    const path = this.$route.name;
    return path === "company-profile";
  }
}
