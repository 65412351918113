






























































































































































































































import api from "@/services/api";
import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import GradientButton from "../components/GradientButton.vue";
import handleValidationErrors from "../store/handle-validation-errors";

@Component({
  components: { GradientButton },
  computed: mapState(["isLoading"]),
})
export default class Home extends Vue {
  email = "";
  name = "";
  message = "";

  onSubmit(): void {
    const { email, name, message } = this;
    api
      .contactUs({ email, name, message, from: "WEB" })
      .then(() => {
        (this as any).$swal("Thank You!", "We got your message", "success");
      })
      .catch((e) => {
        (this as any).$swal(
          "Error!",
          handleValidationErrors(e.response.data).join(" "),
          "error"
        );
      });
  }

  get currentYear(): number {
    return new Date().getFullYear();
  }
}
