


























































































import { Component, Vue } from "vue-property-decorator";
import ProfileNavigationItem from "@/components/ProfileNavigationItem.vue";
import { mapState } from "vuex";
import api from "@/services/api";
import moment from "moment";

@Component({
  components: {
    ProfileNavigationItem,
  },
  computed: mapState(["isLoading"]),
})
export default class AdminSensors extends Vue {
  sensors = [] as any[];
  view = "ALL_SENSORS";
  user_id = null;

  created() {
    api.admins.getSensors().then((r) => (this.sensors = r.data));
  }

  onNewSensor() {
    const { user_id } = this;
    api.admins
      .createNewSensor({ user_id })
      .then((r) => {
        this.sensors.push(r.data);
        (this as any).$swal("Okay!", "Sensor has been added", "success");
      })
      .catch((e) => {
        const error = e.response?.data || e.response?.statusText || e.message;

        (this as any).$swal("Error!", error, "error");
      });
  }

  onDeleteSensor(sensor_id: string, index: number) {
    console.log("deleting sensor", sensor_id);
    api.admins
      .deleteSensor(sensor_id)
      .then((r) => {
        console.log(r.data);
        this.sensors.splice(index, 1);
        (this as any).$swal("Okay!", "Sensor Deleted", "success");
      })
      .catch((e) => {
        const error = e.response.data || e.response.statusText;
        (this as any).$swal("Error!", error, "error");
      });
  }

  date(date: string) {
    return moment(date).format("Do MMMM, h:mm a");
  }
}
