var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gradient-container"},[_c('h1',{staticClass:"page-title"},[_vm._v("Company - Sign up")]),_c('div',{staticClass:"form-card"},[_c('div',{staticClass:"errors-array-response"},_vm._l((_vm.responseErrors),function(err,index){return _c('ValidationError',{key:index},[_vm._v(" * "+_vm._s(err)+" ")])}),1),_c('form',{attrs:{"id":"sign-up-form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSignup($event)}}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"sign-up-fields-divisor"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Company name")]),_c('ValidationProvider',{attrs:{"name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],attrs:{"name":"name","type":"text","required":"","minlength":"5","placeholder":"Enter your name"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_c('ValidationError',[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Company email")]),_c('ValidationProvider',{attrs:{"name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"name":"email","type":"email","required":"","placeholder":"Enter your email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('ValidationError',[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Company phone")]),_c('ValidationProvider',{attrs:{"name":"Phone","rules":{ regex: _vm.REGEX.PHONE }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone_number),expression:"phone_number"}],attrs:{"name":"phone_number","type":"tel","required":"","placeholder":"Enter your phone number"},domProps:{"value":(_vm.phone_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone_number=$event.target.value}}}),_c('ValidationError',[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Company password")]),_c('ValidationProvider',{attrs:{"name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"name":"password","type":"password","placeholder":"Enter your password","minlength":"8","required":""},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('ValidationError',[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)]),_c('div',{staticClass:"location-warning",class:{ 'location-error': _vm.locationError }},[_vm._v(" * You need to allow access to your location. "),_c('span',{on:{"click":_vm.onAskForLocation}},[_vm._v("Click here")])]),_c('GradientButton',{staticClass:"submit-button",attrs:{"type":"submit","disabled":invalid || !_vm.location,"isLoading":_vm.isLoading}},[_vm._v("Sign up")])]}}])})],1)]),_c('div',{staticClass:"have-account-text"},[_vm._v(" Already have account? "),_c('router-link',{staticClass:"sign-in-link",attrs:{"to":{ name: 'company-signin' }}},[_vm._v("Sign in now")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }