






import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class GradientButton extends Vue {
  @Prop({ default: false, type: Boolean }) isLoading!: boolean;
}
